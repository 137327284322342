import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://9654216ece4aa212becf14a419113971@o4505394065702912.ingest.us.sentry.io/4508037829623808",
  integrations: [],
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
