import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCFXF4KumNqPRSz0DyLmpwcAxaKmvi13as",
  authDomain: "https://app.mindfulread.app/",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
