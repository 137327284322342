import { Dialog } from '@headlessui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnboardingContext } from './OnboardingContext';
import useOnboardingForm from './useOnboardingForm';

function OnboardingScreenTwo() {
  const { onboardingData, updateOnboardingData } = useContext(OnboardingContext);
  const navigate = useNavigate();
  const { inputValue, handleInputChange, handleKeyPress, isButtonDisabled } = useOnboardingForm(onboardingData.kindleEmail, () => {
    handleNext();
  });
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleNext = () => {
    updateOnboardingData({ kindleEmail: inputValue });
    navigate('/onboarding/step-3');
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="px-4 flex flex-col justify-center items-center min-h-screen">
      <h2 className="mb-4 text-xl font-semibold">What's your Kindle email?</h2>
      <input
        ref={inputRef}
        type="email"
        value={inputValue}
        onChange={(e) => handleInputChange(e)}
        onKeyDown={handleKeyPress}
        placeholder="Your Kindle email"
        className="mb-4 p-2 border rounded w-64"
      />
      <a href="https://www.amazon.com/sendtokindle/email" target="_blank" className="mb-2 text-blue-500 hover:text-blue-700">I don't know</a>
      <button onClick={() => setIsPopupOpen(true)} className="mb-4 text-blue-500 hover:text-blue-700">I don't have Kindle</button>
      <button
        onClick={handleNext}
        disabled={isButtonDisabled}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full disabled:opacity-50"
      >
        Next
      </button>

      <Dialog open={isPopupOpen} onClose={() => setIsPopupOpen(false)} className="fixed z-10 inset-0 overflow-y-auto">
        <Dialog.Panel className="relative m-4 bg-white p-6 rounded-md shadow-lg max-w-sm mx-auto">
          <Dialog.Title className="text-lg font-bold">No Kindle? That's fine.</Dialog.Title>
          <Dialog.Description className="mt-2">
            MindfulRead also works with other eBooks.
            <br /><br />
            <b>Only requirements are:</b>
            <p>- ePub support. Most eBooks support it</p>
            <p>- Send-to-book service via email</p>
            <br />
            <p>Please consult the manual for your device to check if these requirements are met. If so - just use the email of your device on this step.</p>
          </Dialog.Description>
          <button
            onClick={() => setIsPopupOpen(false)}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          >
            Close
          </button>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
}

export default OnboardingScreenTwo;
