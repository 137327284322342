import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserData, fetchUserSettings } from './Api';

function MainScreen() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: '',
    name: '',
    articles_in_the_queue: 0,
    articles_added_today: 0,
    articles_read: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserData();
        setUserData(data);

        const settings = await fetchUserSettings();
        
        if (settings === null) {
          navigate('/onboarding');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [navigate]);

  return (
    <div className="h-screen bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/background-image.png')` }}>
      <div className="bg-white bg-opacity-75 rounded-lg shadow-lg p-8 m-4 max-w-md w-full text-center">
        <h1 className="text-2xl font-bold mb-4">Hello, {userData.name}</h1>
        <div className="mt-8 mb-4">
      </div>

        <p className="mb-2">You have <span className="font-bold">{userData.articles_in_the_queue}</span> articles in your list.</p>
        <p className="mb-2">Today, you added <span className="font-bold">{userData.articles_added_today}</span> articles.</p>
        <p className="mb-4">You have read <span className="font-bold">{userData.articles_read}</span> articles in your digests.</p>
        <button
          className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => navigate('/settings')}
        >
          Update your digest settings
        </button>
        <div className="mt-8 mb-4 border-t border-gray-200 pt-4">
          <button
            className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => window.open('https://mindfulread.app/faq', "_blank")}
          >
            <i className="fas fa-question-circle"></i>
            How to use?
          </button>
        </div>
        <div className="mt-8 mb-4 border-t border-gray-200 pt-4">
        <button
          className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => navigate('/articles')}
        >
          Articles
        </button>
      </div>
      </div>
    </div >
  );
}

export default MainScreen;
