import React from 'react';

const Header = () => {
  return (
    <header className="bg-gray-800 bg-opacity-80 text-white p-4">
      <div className="flex items-center">
        <a href="/" className="mr-4">
          <img src="/logo192.png" alt="Favicon" className="w-6 h-6" />
        </a>
        <h1 className="text-2xl font-semibold">MindfulRead</h1>
      </div>
    </header>
  );
};

export default Header;
