import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { listArticles, createCustomDigest, orderArticles, deleteArticles } from './Api';
import { FaTrash, FaPlus, FaEdit } from 'react-icons/fa';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [startTouch, setStartTouch] = useState(null);
  const [swipeOffset, setSwipeOffset] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [digestName, setDigestName] = useState('');
  const [autoGenerate, setAutoGenerate] = useState(false);


const fetchMoreData = async (query) => {
  const lastId = articles.length > 0 ? articles[articles.length - 1].sorting_id : -1;
  const newArticles = await listArticles(10, lastId, query);

  if (newArticles.length === 0) {
    setHasMore(false);
  } else {
    setArticles((prevArticles) => {
      const combinedArticles = [...prevArticles, ...newArticles];
      const uniqueArticles = Array.from(new Set(combinedArticles.map(a => a.id)))
        .map(id => combinedArticles.find(a => a.id === id));
      return uniqueArticles;
    });
  }
};

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchQuery.length > 3 || searchQuery === '') {
        setDebouncedQuery(searchQuery);
      }
    }, 200);

    return () => clearTimeout(handler);
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      setHasMore(true);
      setArticles([]);
      await fetchMoreData(debouncedQuery);
    };

    if (debouncedQuery.length > 3 || debouncedQuery === '') {
      fetchData();
    }
  }, [debouncedQuery]);

  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  const handleCheckboxChange = (articleId) => {
    setSelectedArticles((prevSelected) =>
      prevSelected.includes(articleId)
        ? prevSelected.filter((id) => id !== articleId)
        : [...prevSelected, articleId]
    );
  };

  const handleTouchStart = (e, articleId) => {
    setStartTouch(e.touches[0].clientX);
    setSwipeOffset((prev) => ({ ...prev, [articleId]: 0 }));
  };

  const handleTouchMove = (e, articleId) => {
    if (startTouch !== null) {
      const currentTouch = e.touches[0].clientX;
      const offset = currentTouch - startTouch;
      setSwipeOffset((prev) => ({ ...prev, [articleId]: offset }));
    }
  };

  const handleTouchEnd = (articleId) => {
    if (Math.abs(swipeOffset[articleId]) > 50) {
      handleCheckboxChange(articleId);
    }
    setSwipeOffset((prev) => ({ ...prev, [articleId]: 0 }));
    setStartTouch(null);
  };

const handleDeleteSelected = async () => {
  await deleteArticles({ article_ids: selectedArticles });

  setArticles((prevArticles) =>
    prevArticles.filter((article) => !selectedArticles.includes(article.id))
  );
  setSelectedArticles([]);

  toast.success('Articles are deleted from queue');
};

const handleIncludeInDigest = async () => {
  await orderArticles({ article_ids: selectedArticles });
  setSelectedArticles([]); // Clear selection after operation
  toast.success('Articles will appear in the next digest');
};

  const getStatusTextAndStyle = (status) => {
    switch (status) {
      case 2:
        return { text: '', style: '' };
      case 1:
      case 0:
        return { text: 'Not processed yet', style: '' };
      default:
        return { text: 'Error processing article', style: 'text-red-500', tooltip: 'Something went wrong' };
    }
  };

  const trimSummary = (summary) => {
    if (summary.length <= 400) {
      return summary;
    } else {
      return summary.slice(0, 400) + '...';
    }
  };

    const handleOpenModal = () => {
    setModalIsOpen(true);
    setDigestName('');
    setAutoGenerate(false);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleAutoGenerateChange = () => {
    setAutoGenerate((prev) => !prev);
    if (!autoGenerate) setDigestName('');
  };

const handleSendDigest = async (isScheduled) => {
  await createCustomDigest({
    name: autoGenerate ? null : digestName,
    is_scheduled: isScheduled,
    article_ids: selectedArticles,
  });
  handleCloseModal();
  setSelectedArticles([]); // Clear selection after creating digest
  toast.success(`Digest has been ${isScheduled ? 'planned' : 'sent now'}`);
};

  return (
    <div>
      <input
        type="text"
        placeholder="Search articles..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="border p-2 rounded mb-4 w-full"
      />
      <InfiniteScroll
        dataLength={articles.length}
        next={() => fetchMoreData(debouncedQuery)}
        hasMore={hasMore}
        loader={<p>Loading...</p>}
      >
        <ul className="space-y-4">
          {articles.map((article) => {
            const isSelected = selectedArticles.includes(article.id);
            return (
<li
  key={article.id}
  className={`border p-4 rounded-lg shadow-md flex md:flex-row flex-col items-start relative ${
    isSelected ? 'bg-blue-50 border-blue-500' : 'bg-white'
  } transition-colors duration-200`}
  style={{
    transform: `translateX(${swipeOffset[article.id] || 0}px)`, // Move only the swiped item
    touchAction: 'pan-y', // Prevent page scroll
  }}
  onTouchStart={(e) => handleTouchStart(e, article.id)}
  onTouchMove={(e) => handleTouchMove(e, article.id)}
  onTouchEnd={() => handleTouchEnd(article.id)}
>
  <a href={article.url} target="_blank" rel="noopener noreferrer" className="w-full md:w-40 flex-shrink-0 mb-4 md:mb-0 md:mr-4">
    <img
      src={article.image}
      alt="Thumbnail"
      className="w-full h-40 md:h-24 object-cover rounded-lg" // Full width for small screens, fixed size for larger screens
    />
  </a>
  <div className="flex-grow">
    <h2 className="text-xl font-semibold">
      <a href={article.url} target="_blank" rel="noopener noreferrer">
        {article.title}
      </a>
    </h2>
    <p className={`text-gray-600 mt-2 ${getStatusTextAndStyle(article.status).style}`} title={getStatusTextAndStyle(article.status).tooltip}>
      {getStatusTextAndStyle(article.status).text}
    </p>
    <p className="text-gray-600">{trimSummary(article.summary)}</p>
    <p className="text-gray-500 mt-2">
      {new Date(article.created_at).toLocaleDateString()}
    </p>
  </div>
  <div className="hidden md:flex items-center ml-4">
    <input
      type="checkbox"
      className="form-checkbox h-5 w-5 text-blue-600"
      checked={isSelected}
      onChange={() => handleCheckboxChange(article.id)}
    />
  </div>
</li>
            );
          })}
        </ul>
      </InfiniteScroll>

{selectedArticles.length > 0 && (
        <div className="fixed bottom-0 left-0 w-full bg-gray-100 p-2 md:p-4 shadow-md flex justify-between items-center space-x-2 overflow-x-auto">
          <span className="text-sm md:text-base">{selectedArticles.length} article(s) selected</span>
          <div className="flex space-x-2">
            <button
              onClick={handleDeleteSelected}
              className="bg-red-500 text-white px-2 py-1 md:px-4 md:py-2 rounded flex items-center justify-center"
              title="Delete"
            >
              <FaTrash className="md:hidden" /> {/* Icon only on mobile */}
              <span className="hidden md:inline">Delete</span>
            </button>
            <button
              onClick={handleIncludeInDigest}
              className="bg-blue-500 text-white px-2 py-1 md:px-4 md:py-2 rounded flex items-center justify-center"
              title="Include in Next Digest"
            >
              <FaPlus className="md:hidden" /> {/* Icon only on mobile */}
              <span className="hidden md:inline">Include in Next Digest</span>
            </button>
            <button
              onClick={handleOpenModal}
              className="bg-green-500 text-white px-2 py-1 md:px-4 md:py-2 rounded flex items-center justify-center"
              title="Create Custom Digest"
            >
              <FaEdit className="md:hidden" /> {/* Icon only on mobile */}
              <span className="hidden md:inline">Create Custom Digest</span>
            </button>
          </div>
        </div>
      )}
            <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className="bg-white rounded-lg p-4 max-w-lg mx-auto my-8 shadow-lg overflow-y-auto"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Create Custom Digest</h2>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Digest Name</label>
          <textarea
            value={digestName}
            onChange={(e) => setDigestName(e.target.value)}
            disabled={autoGenerate}
            placeholder="Enter digest name"
            className="w-full p-2 border rounded resize-none"
            rows="3"
          />
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={autoGenerate}
            onChange={handleAutoGenerateChange}
            className="form-checkbox text-blue-600 h-4 w-4"
          />
          <span className="ml-2">Generate automatically</span>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => handleSendDigest(true)}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Send as Next Scheduled
          </button>
        </div>
      </Modal>
    </div>
  );
};


export default ArticleList;