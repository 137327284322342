import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from './firebase';

const FirebaseTokenContext = createContext();

export const useFirebaseToken = () => {
  const context = useContext(FirebaseTokenContext);
  if (!context) {
    throw new Error('useFirebaseToken must be used within a FirebaseTokenProvider');
  }
  return context;
};

export const FirebaseTokenProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <FirebaseTokenContext.Provider value={{ user }}>
      {children}
    </FirebaseTokenContext.Provider>
  );
};
