import axios from 'axios';
import firebase from './firebase';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeader = async (token = "") => {
  if (token == "") {
    token = await firebase.auth().currentUser.getIdToken();
  }

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchUserData = async () => {
  const authHeader = await getAuthHeader();
  const response = await axios.get(`${BASE_URL}/me`, authHeader);
  return response.data;
};

export const fetchUserSettings = async () => {
  const authHeader = await getAuthHeader();
  const response = await axios.get(`${BASE_URL}/settings`, authHeader);
  return response.data;
};

export const updateUserSettings = async (settings) => {
  const authHeader = await getAuthHeader();
  await axios.post(`${BASE_URL}/settings`, settings, authHeader);
};

export const updateUserName = async (name) => {
  const authHeader = await getAuthHeader();
  await axios.post(`${BASE_URL}/me`, { name }, authHeader);
};

export const exchangeToken = async (token) => {
  const authHeader = await getAuthHeader(token);
  const response = await axios.get(`${BASE_URL}/exchange_token`, authHeader);
  return response.data;
};

export const listArticles = async (limit, last_id, search) => {
  const authHeader = await getAuthHeader();
  const response = await axios.get(`${BASE_URL}/articles`, {
    params: { limit, last_id , search},
    ...authHeader,
  });
  return response.data;
};

export const session = async () => {
  const authHeader = await getAuthHeader();
  const response = await axios.get(`${BASE_URL}/session`, authHeader);
  return response.data;
};

export const createCustomDigest = async ({ name, is_scheduled, article_ids }) => {
  const authHeader = await getAuthHeader();
  try {
    const response = await axios.post(
      `${BASE_URL}/custom_digest`,
      {
        name,
        is_scheduled,
        article_ids,
      },
      authHeader
    );
    return response.data;
  } catch (error) {
    console.error("Error creating custom digest:", error);
    throw error;
  }
};

export const orderArticles = async({ article_ids }) => {
    const authHeader = await getAuthHeader();
    try {
        const response = await axios.post(
            `${BASE_URL}/order_article`,
            {
                article_ids,
            },
            authHeader
        );
        return response.data;
    } catch (error) {
        console.error("Error ordering article: ", error);
        throw error;
    }
};


export const deleteArticles = async({ article_ids }) => {
    const authHeader = await getAuthHeader();
    try {
        const response = await axios.post(
            `${BASE_URL}/delete`,
            {
                article_ids,
            },
            authHeader
        );
        return response.data;
    } catch (error) {
        console.error("Error deleting article: ", error);
        throw error;
    }
};