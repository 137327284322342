import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnboardingContext } from './OnboardingContext';
import useOnboardingForm from './useOnboardingForm';

function OnboardingScreenOne() {
  const { onboardingData, updateOnboardingData } = useContext(OnboardingContext);
  const { inputValue, handleInputChange, handleKeyPress, isButtonDisabled } = useOnboardingForm(onboardingData.name, () => {
    handleNext();
  });
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleNext = () => {
    updateOnboardingData({ name: inputValue });
    navigate('/onboarding/step-2');
  };

  return (
    <div className="px-4 flex flex-col justify-center items-center min-h-screen">
      <h2 className="mb-4 text-xl font-semibold">Let's get to know each other. What's your name?</h2>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={(e) => handleInputChange(e)}
        onKeyDown={handleKeyPress}
        placeholder="Your name"
        className="mb-4 p-2 border rounded w-64"
      />
      <button
        onClick={handleNext}
        disabled={isButtonDisabled}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full disabled:opacity-50"
      >
        Next
      </button>
    </div>
  );
}

export default OnboardingScreenOne;
