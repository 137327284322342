import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import firebase from './firebase';
import humanizeFirebaseError from './firebaseErrors';

function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const from = new URLSearchParams(location.search).get('from') || '/';

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');

    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      navigate("/");
    } catch (error) {
      const friendlyErrorMessage = humanizeFirebaseError(error.code);
      toast.error(friendlyErrorMessage);
      setError(friendlyErrorMessage);
    }
  };

  const handleLoginRedirect = () => {
    navigate(`/login?from=${encodeURIComponent(from)}`);
  };

  const inputStyle = (baseStyle) => `${baseStyle} ${error ? 'border-red-500' : 'border-gray-300'}`;

  return (
    <div className="h-screen bg-cover bg-center" style={{ backgroundImage: `url('/background-image.png')` }}>
      <div className="flex items-center justify-center h-full">
        <div className="bg-white bg-opacity-75 rounded-lg shadow-lg p-8 m-4 max-w-sm w-full">
          <h2 className="text-2xl font-bold mb-4 text-center">Welcome to MindfulRead</h2>
          <p className="text-center mb-6 text-m">
            Enjoy a new way to manage your articles reading list.
          </p>
          <form onSubmit={handleRegister} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input 
                type="email" 
                className={inputStyle("mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500")}
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <input 
                type="password" 
                className={inputStyle("mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500")}
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
            </div>
            <button 
              type="submit" 
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Register
            </button>
          </form>
          <hr className="my-4" />
          <div className="mt-4">
            <p className="text-m text-center mt-4 mb-2">Already have an account?</p>
            <button
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={handleLoginRedirect}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
