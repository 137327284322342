import React from 'react';
import { useNavigate } from 'react-router-dom';

function OnboardingTitleScreen() {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate('/onboarding/step-1');
    };

    return (
        <div className="px-4 flex flex-col justify-center items-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url('/background-image.png')` }}>
            <div className="bg-white bg-opacity-95 rounded-lg shadow-lg p-8 m-4 w-full md:w-2/3 lg:w-1/2 xl:w-1/2 text-center">
                <h1 className="text-3xl font-bold mb-4">👋 Welcome to MindfulRead BETA!</h1>
                <p className="mb-4 text-lg">
                    Let me introduce myself.
                    My name is Nikolay and I am the creator of MindfulRead.
                </p>
                <p className="mb-4 text-lg">
                    I am and I always been a passionate reader. Either is it a book or an article - I enjoy it.<br/>
                    For a long time already I've been struggling with read it later services.
                    I was finding myself adding tons of articles to my reading list and rarely returning to them.
                </p>
                <p className="mb-4 text-lg">
                    
                </p>
                <p className="mb-4 text-lg">
                    Since I became a father in December 2022 it became even worth. I had no time, but I still was adding a lot of things to my later.
                    Also, around that time I found out that I can find time for my Kindle - just because it requires 0 effort to start reading.
                </p>
                <p className="mb-4 text-lg">
                    No choice paralysis. No distractions. Just reading.
                    And I wondered - what if I could reproduce the same experience with my reading list?
                </p>
                <p className="mb-4 text-lg">
                    I built this service for myself first. I use it every day. And I hope that it will help you too if you are in the same position as I am.
                </p>
                <p className="mb-4 text-lg">
                    <b>A few limitations that it has now:</b>
                    <ul className="list-disc list-inside mb-4">
                        <li className="mb-2">Adding articles is supported in Chrome only. Desktop/mobile Safari upcoming soon</li>
                        <li className="mb-2">Mobile application is WIP, coming soon too. Beta testers are welcomed though 😉</li>
                    </ul>
                </p>
                <p className='mb-4 text-lg font-bold'>
                    I will notify you on all the new launches.
                    And meanwhile, if you are ready,
                </p>


                <button
                    onClick={handleGetStarted}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                >
                    Get Started
                </button>
            </div>
        </div>
    );
}

export default OnboardingTitleScreen;
