const timezones = [
    { label: "UTC", value: "UTC" },
    { label: "Pacific/Honolulu (Hawaii)", value: "Pacific/Honolulu" },
    { label: "America/Anchorage (Alaska)", value: "America/Anchorage" },
    { label: "America/Los_Angeles (Pacific Time)", value: "America/Los_Angeles" },
    { label: "America/Denver (Mountain Time)", value: "America/Denver" },
    { label: "America/Chicago (Central Time)", value: "America/Chicago" },
    { label: "America/New_York (Eastern Time)", value: "America/New_York" },
    { label: "America/Sao_Paulo (Brazil)", value: "America/Sao_Paulo" },
    { label: "Atlantic/Azores (Azores)", value: "Atlantic/Azores" },
    { label: "Europe/London (United Kingdom)", value: "Europe/London" },
    { label: "Europe/Berlin (Central European Time)", value: "Europe/Berlin" },
    { label: "Europe/Athens (Eastern European Time)", value: "Europe/Athens" },
    { label: "Asia/Dubai (United Arab Emirates)", value: "Asia/Dubai" },
    { label: "Asia/Karachi (Pakistan)", value: "Asia/Karachi" },
    { label: "Asia/Kolkata (India)", value: "Asia/Kolkata" },
    { label: "Asia/Bangkok (Thailand)", value: "Asia/Bangkok" },
    { label: "Asia/Hong_Kong (China)", value: "Asia/Hong_Kong" },
    { label: "Asia/Tokyo (Japan)", value: "Asia/Tokyo" },
    { label: "Australia/Sydney (Australia)", value: "Australia/Sydney" },
    { label: "Pacific/Auckland (New Zealand)", value: "Pacific/Auckland" }
  ];
  
  export default timezones;
  