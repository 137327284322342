import { useState } from 'react';

const useOnboardingForm = (initialValue, navigateTo) => {
    const [inputValue, setInputValue] = useState(initialValue);
    const [isButtonDisabled, setIsButtonDisabled] = useState(!initialValue);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setIsButtonDisabled(!e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isButtonDisabled) {
            navigateTo();
        }
    };

    return {
        inputValue,
        handleInputChange,
        handleKeyPress,
        isButtonDisabled,
    };
};

export default useOnboardingForm;
