import React, { useEffect } from 'react';
import { session } from './Api';
import firebase from './firebase';

const ExtensionLogin = () => {
    useEffect(() => {
        async function sendTokenToExtension() {
            const user = firebase.auth().currentUser;
            const redirectTo = new URLSearchParams(window.location.search).get(
                'redirectTo'
            );

            if (user) {
                const sessionToken = await session();
                console.log('Sending data to the extension');
                window.postMessage({ type: 'LOGIN_DATA', text: sessionToken }, '*');

                window.addEventListener('message', (event) => {
                    if (event.source != window) return;

                    if (event.data.type && (event.data.type == 'EXTENSION_LOGIN_COMPLETE')) {
                        console.log('Extension login complete');
                        document.getElementById('status').innerHTML = '<p class="text-green-800">Login complete</p>';
                        if (window.chrome && redirectTo !== null) {
                            window.location = redirectTo + "?session=" + sessionToken;
                        } else {
                            document.getElementById('status').innerHTML = '<p class="text-green-800">Login complete. Please close this tab.</p>';
                        }
                    }
                })
            } else {
                console.error('No user logged in');
                window.close();
            }
        }

        window.addEventListener('MindfulReadLoginJsLoaded', () => {
            sendTokenToExtension();
        });

        const event = new CustomEvent('MindfulReadReadyToLogin');
        window.dispatchEvent(event);
        console.log("MindfulReadReadyToLogin dispatched");
    }, []);

    return (
        <div className="h-screen bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/background-image.png')` }}>
            <div className="bg-white bg-opacity-75 rounded-lg shadow-lg p-8 m-4 max-w-md w-full text-center">
                <div id="status">
                    <h2 className="text-2xl font-bold mb-4">Establishing connection with the extension...</h2>
                    <p className="text-gray-700">Please, make sure that the MindfulRead browser extension has access to this host.</p>
                </div>
            </div>
        </div>
    );
};

export default ExtensionLogin;
