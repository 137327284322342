import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnboardingContext } from './OnboardingContext';

function Toggle({ isOn, onToggle }) {
  return (
    <span onClick={onToggle} className={`inline-block w-8 h-4 rounded-full cursor-pointer ${isOn ? 'bg-blue-500' : 'bg-gray-200'}`}>
      <span className={`block w-4 h-4 bg-white rounded-full transform transition-transform ${isOn ? 'translate-x-4' : ''}`}></span>
    </span>
  );
}

function OnboardingScreenThree() {
  const { onboardingData, updateOnboardingData } = useContext(OnboardingContext);
  const navigate = useNavigate();
  const [selectedDays, setSelectedDays] = useState(onboardingData.selected_days || []);

  const handleNext = () => {
    updateOnboardingData({ selected_days: selectedDays });
    navigate('/onboarding/step-4'); // Update this to your next step
  };

  const toggleDay = (day) => {
    setSelectedDays(selectedDays.includes(day)
      ? selectedDays.filter(d => d !== day)
      : [...selectedDays, day].sort());
  };

  return (
    <div className="px-4 flex flex-col justify-center items-center min-h-screen">
      <h2 className="mb-4 text-xl font-semibold">How often do you want to receive digests?</h2>
      <div className="flex flex-col items-center">
        {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
          <div key={day} className="flex items-center mb-2">
            <label className="mr-2 w-20 text-right">{day}</label>
            <Toggle isOn={selectedDays.includes(index)} onToggle={() => toggleDay(index)} />
          </div>
        ))}
      </div>
      <button
        onClick={handleNext}
        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
      >
        Next
      </button>
    </div>
  );
}

export default OnboardingScreenThree;
