function humanizeFirebaseError(errorCode) {
    switch (errorCode) {
        case 'auth/email-already-in-use':
            return 'This email is already in use. Please try another email.';
        case 'auth/invalid-email':
            return 'Invalid email. Please enter a valid email address.';
        case 'auth/weak-password':
            return 'Password is too weak. Please use a stronger password.';
        case 'auth/invalid-login-credentials':
            return 'Invalid login and/or password.';
        default:
            return 'An unexpected error occurred. Please try again. Code: ' + errorCode;
    }
};

export default humanizeFirebaseError;

