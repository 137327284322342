import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUserName, updateUserSettings } from '../Api';
import timezones from '../timezones';
import { OnboardingContext } from './OnboardingContext';

function OnboardingScreenFour() {
  const { onboardingData, updateOnboardingData } = useContext(OnboardingContext);
  const navigate = useNavigate();
  const [articlesPerDigest, setArticlesPerDigest] = useState(onboardingData.articles_per_digest ?? 5);

  const getClosestTimezone = () => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezones.find(tz => tz.value === browserTimezone)?.value || 'UTC';
  };

  const handleFinishSetup = async () => {
    try {
      const settingsData = {
        email: onboardingData.kindleEmail,
        articles_per_digest: articlesPerDigest,
        timezone: onboardingData.timezone ? onboardingData.timezone : getClosestTimezone(),
        selected_days: onboardingData.selected_days,
      };

      await updateUserSettings(settingsData);
      if (onboardingData.name) {
        await updateUserName(onboardingData.name);
      }

      navigate('/');
    } catch (error) {
      console.error('Error updating settings:', error);
      toast.error('Failed to complete setup. Please try again.');
    }
  };

  const increment = () => {
    if (articlesPerDigest < 10) setArticlesPerDigest(articlesPerDigest + 1);
  };

  const decrement = () => {
    if (articlesPerDigest > 1) setArticlesPerDigest(articlesPerDigest - 1);
  };

  return (
    <div className="px-4 flex flex-col justify-center items-center min-h-screen">
      <h2 className="mb-4 text-xl font-semibold">How many articles per digest do you want to read?</h2>
      <div className="flex items-center mb-4">
        <button
          onClick={decrement}
          disabled={articlesPerDigest <= 1}
          className="bg-blue-500 text-white rounded-full w-10 h-10 flex justify-center items-center disabled:bg-gray-300 mx-2"
        >
          -
        </button>
        <span className="text-3xl font-semibold mx-4">{articlesPerDigest}</span>
        <button
          onClick={increment}
          disabled={articlesPerDigest >= 10}
          className="bg-blue-500 text-white rounded-full w-10 h-10 flex justify-center items-center disabled:bg-gray-300 mx-2"
        >
          +
        </button>
      </div>
      <button
        onClick={handleFinishSetup}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
      >
        Finish Setup
      </button>
    </div>
  );
}

export default OnboardingScreenFour;
