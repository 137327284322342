import React, { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { exchangeToken } from './Api';
import ArticlesList from './ArticlesList';
import ExtensionLogin from './ExtensionLogin';
import { FirebaseTokenProvider } from './FirebaseTokenContext';
import Header from './Header';
import Login from './Login';
import MainScreen from './MainScreen';
import Register from './Register';
import Settings from './Settings';
import useAuthState from './authService';
import firebase from './firebase';
import { OnboardingProvider } from './onboarding/OnboardingContext';
import OnboardingScreenFour from './onboarding/OnboardingScreenFour';
import OnboardingScreenOne from './onboarding/OnboardingScreenOne';
import OnboardingScreenThree from './onboarding/OnboardingScreenThree';
import OnboardingScreenTwo from './onboarding/OnboardingScreenTwo';
import OnboardingTitleScreen from './onboarding/OnboardingTitleScreen';
import { isCustomiOSUserAgent } from './userAgent';

function RedirectToLogin() {
  const location = useLocation();
  return <Navigate to="/login" state={{ from: location.pathname }} />;
}

function RedirectToRegister() {
  const location = useLocation();
  return <Navigate to="/register" state={{ from: location.pathname }} />;
}

function App() {
  const { user, loading } = useAuthState();
  const [initializing, setInitializing] = useState(true);
  const isIosApp = isCustomiOSUserAgent();

  useEffect(() => {
    const firebaseToken = new URLSearchParams(window.location.search).get('firebase-token');

    if (firebaseToken) {
      exchangeToken(firebaseToken).then((data) => {
        firebase.auth().signInWithCustomToken(data.token)
          .then(() => {
            setInitializing(false);
          })
          .catch((error) => {
            console.error(error);
            setInitializing(false);
          });
      });
    } else {
      setInitializing(false);
    }
  }, []);

  if (loading || initializing) {
    return (
      <div className="h-screen bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/background-image.png')` }}>
        <div className="bg-white bg-opacity-75 rounded-lg shadow-lg p-8 m-4 max-w-md w-full text-center">
          <p className="text-m mb-4">Application is loading...</p>
        </div>
      </div>)
  }

  return (
    <>
      <ToastContainer />
      <FirebaseTokenProvider>
        <OnboardingProvider>
        <Header />
          <Router>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/settings" element={user ? <Settings /> : <RedirectToLogin />} />
              <Route path="/" element={user ? <MainScreen /> : <RedirectToRegister />} />
              <Route path="/extension/login" element={user ? <ExtensionLogin /> : <RedirectToLogin />} />

              <Route path="/onboarding" element={user ? <OnboardingTitleScreen /> : <RedirectToLogin />} />
              <Route path="/onboarding/step-1" element={user ? <OnboardingScreenOne /> : <RedirectToLogin />} />
              <Route path="/onboarding/step-2" element={user ? <OnboardingScreenTwo /> : <RedirectToLogin />} />
              <Route path="/onboarding/step-3" element={user ? <OnboardingScreenThree /> : <RedirectToLogin />} />
              <Route path="/onboarding/step-4" element={user ? <OnboardingScreenFour /> : <RedirectToLogin />} />

              <Route path="/articles" element={user ? <ArticlesList /> : <RedirectToLogin />} />
            </Routes>
          </Router>
        </OnboardingProvider>
      </FirebaseTokenProvider>
    </>
  );
}

export default App;
