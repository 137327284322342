import React, { createContext, useEffect, useState } from 'react';
import { fetchUserData, fetchUserSettings } from '../Api';

export const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
  const [onboardingData, setOnboardingData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchUserData();
        const settingsData = await fetchUserSettings();

        setOnboardingData({
          name: userData.name,
          kindleEmail: settingsData.email,
          selected_days: settingsData.selected_days,
          articles_per_digest: settingsData.articles_per_digest,
        });
      } catch (error) {
        console.error('Error fetching onboarding data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const updateOnboardingData = (newData) => {
    setOnboardingData({ ...onboardingData, ...newData });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <OnboardingContext.Provider value={{ onboardingData, updateOnboardingData }}>
      {children}
    </OnboardingContext.Provider>
  );
};
