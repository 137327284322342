import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchUserSettings, updateUserSettings } from './Api';
import timezones from './timezones';

function Settings() {
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    email: '',
    articles_per_digest: 5,
    timezone: '',
    selected_days: [],
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await fetchUserSettings();
        setSettings(data);
      } catch (error) {
        toast.error('Failed to load settings.');
      }
    };

    fetchSettings();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateUserSettings(settings);
      toast.success('Settings saved successfully!');
      navigate('/');
    } catch (error) {
      toast.error('Failed to save settings.');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const parsedValue = name === 'articles_per_digest' ? parseInt(value, 10) : value;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: parsedValue,
    }));
  };
  

  const handleCheckboxChange = (day) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      selected_days: prevSettings.selected_days.includes(day)
        ? prevSettings.selected_days.filter((d) => d !== day)
        : [...prevSettings.selected_days, day],
    }));
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url('/background-image.png')` }}>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-lg w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Email:</label>
          <input
            type="email"
            name="email"
            value={settings.email}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Articles per Digest:</label>
          <select
            name="articles_per_digest"
            value={settings.articles_per_digest}
            onChange={handleChange}
            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Timezone:</label>
          <select
            name="timezone"
            value={settings.timezone}
            onChange={handleChange}
            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            {timezones.map((tz) => (
              <option key={tz.value} value={tz.value}>
                {tz.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Days for Digest:</label>
          {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
            <div key={day} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={day}
                checked={settings.selected_days.includes(index)}
                onChange={() => handleCheckboxChange(index)}
                className="mr-2"
              />
              <label htmlFor={day} className="text-gray-700 text-sm">
                {day}
              </label>
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Save Settings
        </button>
      </form>
    </div>
  );
}

export default Settings;
